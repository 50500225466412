<template>
  <v-container class="p-3 mt-5">
    <loading-flux :value="loadingAll"></loading-flux>
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-2">
        {{ title }}
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-row align-content="start" align-md="start" class="mt-1">
      <v-col cols="12" md="4" sm="12">
        <v-btn
          v-if="showActionCreate"
          @click="openModalUpdateOrCreateFormMaterial(null)"
          color="teal accent-4 text-white"
          small
        >
          <v-icon small class="mr-1">
            mdi-plus
          </v-icon>
          Agregar nuevo código
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" sm="12"> </v-col>
      <v-col cols="12" md="4" sm="12"> </v-col>
      <v-col cols="12" md="3" sm="12">
        <v-checkbox
          @click="getFormMaterialsData(1)"
          v-model="filters.only_pending"
          class="mt-4"
        >
          <template v-slot:label>
            <h6 class="mt-2 mx-1 mb-0">
              Materiales pendientes
            </h6>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <v-checkbox
          @click="getFormMaterialsData(1)"
          v-model="filters.only_completed"
          class="mt-4"
        >
          <template v-slot:label>
            <h6 class="mt-2 mx-1 mb-0">
              Materiales ya creados
            </h6>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="pt-0">
        <v-text-field
          v-model="filters.filter"
          @input="getFormMaterialsData(1)"
          class="mt-5"
          clearable
          placeholder="Buscar:"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="mt-2">
        <v-btn
          class="float-right mt-5"
          color="blue darken-2 text-white"
          small
          @click="downloadExcelFormMaterials"
        >
          <v-icon small class="mr-1">
            mdi-export
          </v-icon>
          Exportar
        </v-btn>
      </v-col>
    </v-row>
    <p
      class="text-info mt-5"
      v-if="ifAnyRequestHaveWarning && !currentUserBelongsToSupplying"
    >
      <v-icon class="mr-1" small color="blue darken-2">
        mdi-alert-circle
      </v-icon>
      Una vez que hayas corregido los errores de la solicitud, recuerda marcarla
      como corregida con la acción <b>Marcar solicitud como revisada</b>
    </p>
    <v-data-table
      class="elevation-3 px-3 mb-2 mt-2"
      :headers="wrappedHeaders"
      :items="wrappedFormMaterials"
      :items-per-page="20"
      :page.sync="filters.page"
      :loading="loading"
      loading-text="Cargando materiales"
      item-key="id"
      hide-default-footer
      no-data-text="Sin materiales"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.final_name="{ item }">
        <span class="mt-2 text-left" style="font-size: 11px;">
          {{ item.final_name }}
        </span>
      </template>
      <template v-slot:item.sap_code="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.sap_code ? item.sap_code : "-" }}
        </span>
      </template>
      <template v-slot:item.final_reference_price="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{
            item.final_reference_price
              ? `${item.final_reference_price.toLocaleString("es-CL", {
                  style: "currency",
                  currency: "CLP",
                })}`
              : "-"
          }}
        </span>
      </template>
      <template v-slot:item.final_reference_link="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip
            top
            v-if="
              item.final_reference_link &&
                isLinkMaterialValid(item.final_reference_link)
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :href="
                  item.final_reference_link &&
                  item.final_reference_link.includes('https')
                    ? item.final_reference_link
                    : `https://${item.final_reference_link}`
                "
                target="_blank"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                x-small
                class="mx-2"
              >
                <v-icon>
                  mdi-download
                </v-icon>
              </v-btn>
            </template>
            <span>Ver link de referencia</span>
          </v-tooltip>
          <span v-else class="text-center">
            -
          </span>
        </div>
      </template>
      <template v-slot:item.request_approval_user="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{
            item.request_approval_user
              ? item.request_approval_user.email
              : item.request_user.email
          }}
        </span>
      </template>
      <template v-slot:item.material_group="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.material_group ? item.material_group : "-" }}
        </span>
      </template>
      <template v-slot:item.measurement_unit="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.measurement_unit ? item.measurement_unit : "-" }}
        </span>
      </template>
      <template v-slot:item.item_gasto="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.item_gasto ? item.item_gasto : "-" }}
        </span>
      </template>
      <template v-slot:item.family="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.family ? item.family : "-" }}
        </span>
      </template>
      <template v-slot:item.is_project="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{
            item.is_project == null
              ? "Sin definir"
              : item.is_project
              ? "Proyecto"
              : "CECO"
          }}
        </span>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="d-flex justify-content-center">
          <v-checkbox
            :disabled="true"
            :input-value="
              item.request_status === statusMaterial.created ? true : false
            "
          >
          </v-checkbox>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top v-if="item.rejected">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="red" class="mr-2" v-bind="attrs" v-on="on">
                mdi-cancel
              </v-icon>
            </template>
            <span>
              {{ item.comments[item.comments.length - 1].comment }}
            </span>
          </v-tooltip>
          <v-tooltip top v-if="item.warning">
            <template v-slot:activator="{ on, attrs }">
              <span
                style="font-size: 1.5rem;"
                class="mr-1"
                v-bind="attrs"
                v-on="on"
              >
                ⚠️
              </span>
            </template>
            <span
              >El equipo de Abastecimiento ha indicado que la solicitud tiene
              algunos errores que corregir, revisa la sección de comentarios de
              la solicitud para chequearlos</span
            >
          </v-tooltip>
          <v-menu :close-on-content-click="true">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="blue darken-3" fab x-small outlined>
                <v-icon color="white">
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list rounded>
              <v-list-item
                v-if="
                  (item.request_status == statusMaterial.review ||
                    item.request_status == statusMaterial.pending_review) &&
                    currentUserBelongsToSupplying &&
                    !item.rejected
                "
              >
                <v-list-item-title
                  @click="updateStatusCheckedFormMaterial(item)"
                  x-small
                  class="pointer"
                >
                  <v-icon class="mr-2" color="success">
                    mdi-check
                  </v-icon>
                  Marcar material como creado
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  (item.request_status == statusMaterial.review ||
                    item.request_status == statusMaterial.pending_review) &&
                    currentUserBelongsToSupplying &&
                    !item.rejected
                "
              >
                <v-list-item-title
                  @click="rejectFormMaterial(item)"
                  x-small
                  class="pointer"
                >
                  <v-icon class="mr-2" color="red">
                    mdi-cancel
                  </v-icon>
                  Cancelar Solicitud
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  (item.request_status == statusMaterial.review ||
                    item.request_status == statusMaterial.pending_review) &&
                    currentUserBelongsToSupplying &&
                    !item.rejected &&
                    !item.warning
                "
              >
                <v-list-item-title
                  @click="updateStatusReviewFormMaterial(item, true)"
                  x-small
                  class="pointer"
                >
                  <span class="mr-2" style="font-size: 1.1rem">
                    ⚠️
                  </span>
                  Enviar solicitud a revisión
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  (item.request_status == statusMaterial.review ||
                    item.request_status == statusMaterial.pending_review) &&
                    !item.rejected &&
                    item.warning
                "
              >
                <v-list-item-title
                  @click="updateStatusReviewFormMaterial(item, false)"
                  x-small
                  class="pointer"
                >
                  <span class="mr-2" style="font-size: 1.1rem">
                    👌
                  </span>
                  Marcar solicitud como revisada
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                class="mb-0"
                v-if="
                  item.warning &&
                    item.request_status !== statusMaterial.created &&
                    (currentUserBelongsToSupplying ||
                      (item.request_user &&
                        item.request_user.email === user.email)) &&
                    !item.rejected
                "
              >
                <v-list-item-title
                  @click="openModalUpdateOrCreateFormMaterial(item)"
                  x-small
                  class="pointer"
                >
                  <v-icon class="mr-2" color="warning">
                    mdi-pencil
                  </v-icon>
                  Editar
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                  @click="openModalCommentsFormMaterials(item)"
                  x-small
                  class="pointer"
                >
                  <v-badge
                    :content="item.comments.length ? item.comments.length : '-'"
                    color="success"
                    overlap
                  >
                    <v-icon class="mr-2" color="blue darken-2">
                      mdi-comment-outline
                    </v-icon>
                  </v-badge>
                  <span class="mx-2">
                    Comentarios
                  </span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                  icon
                  @click="openModalFilesMaterial(item)"
                  color="primary"
                  x-small
                  class="pointer"
                >
                  <v-icon class="mr-2">
                    mdi-file-multiple-outline
                  </v-icon>
                  Ver archivos cargados
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:item.request_approval_date="{ item }">
        <span style="font-size: 11px;">
          {{
            item.request_date
              ? moment(item.request_date).format("DD/MM/YYYY HH:mm [hrs]")
              : moment(item.request_approval_date).format(
                  "DD/MM/YYYY HH:mm [hrs]"
                )
          }}
        </span>
      </template>
      <template v-slot:item.sap_date="{ item }">
        <span style="font-size: 11px;">
          {{
            item.sap_date && item.request_status === statusMaterial.created
              ? moment(item.sap_date).format("DD/MM/YYYY HH:mm [hrs]")
              : "-"
          }}
        </span>
      </template>
      <template v-slot:item.time_creation="{ item }">
        <span style="font-size: 11px;">
          {{ getTimeCreationDate(item) }}
        </span>
      </template>
    </v-data-table>
    <div class="float-right">
      <v-pagination
        total-visible="8"
        v-model="filters.page"
        :length="
          formMaterials.number_of_pages ? formMaterials.number_of_pages : 1
        "
      ></v-pagination>
    </div>
    <modal-create-materials
      :modalAction="dialogCreateFormMaterial"
      :closeModalCreateMaterial="closeModalUpdateOrCreateFormMaterial"
      :currentFormMaterial="currentFormMaterial"
      :showAllForm="currentUserBelongsToSupplying"
      @materialCreated="getFormMaterialsData"
    >
    </modal-create-materials>
    <modal-files-material
      :modalAction="dialogFilesFormMaterial"
      :closeModalFileMaterial="closeModalFilesMaterial"
      :currentFormMaterial="currentFormMaterialShowFiles"
    >
    </modal-files-material>
    <modal-comments
      :modalAction="dialogCommentsFormMaterial"
      :closeModalComments="closeModalCommentsFormMaterials"
      :currentItem="currentFormMaterialComments"
      @commentAdded="getFormMaterialsData"
      @commentDeleted="getFormMaterialsData"
      commentType="MATERIAL"
    >
    </modal-comments>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import ModalCreateMaterials from "./components/ModalCreateMaterials.vue";
import moment from "moment";
import ModalFilesMaterial from "./components/ModalFilesMaterial.vue";
import { isUrlValid, calculateDiferenceTwoDates } from "@/helpers/common";
import ModalComments from "@/components/commons/form/ModalComments.vue";
export default {
  components: { ModalCreateMaterials, ModalFilesMaterial, ModalComments },
  props: {
    showActionCreate: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Formulario de creación de materiales",
    },
  },
  mounted() {
    this.getFormMaterialsData();
  },
  beforeDestroy() {
    this.clearFormMaterials();
  },
  data() {
    return {
      moment,
      loading: false,
      debounce: null,
      debounceTime: 500,
      filters: {
        page: 1,
        filter: "",
        only_completed: false,
        only_pending: false,
      },
      statusMaterial: {
        review: "En Revision",
        pending_review: "Por Revisar",
        created: "Aprovada",
      },
      dialogCreateFormMaterial: false,
      dialogCommentsFormMaterial: false,
      dialogFilesFormMaterial: false,
      pageCount: 0,
      loadingAll: false,
      loadingFormMaterials: false,
      currentFormMaterial: null,
      currentFormMaterialShowFiles: null,
      currentFormMaterialComments: null,
      currentUserBelongsToSupplying: false,
      breadcrumb: [
        {
          text: "Proyectos",
          disabled: false,
          href: "/",
        },
        {
          text: this.title,
          disabled: true,
          href: "/supplying/form/materials",
        },
      ],
      tableHeaders: [
        {
          text: "Acción",
          align: "center",
          value: "actions",
          className: "px-0",
          width: "3%",
          sortable: false,
        },
        {
          text: "Descripción",
          sortable: false,
          width: "15%",
          align: "center",
          value: "final_name",
        },
        {
          text: "Código",
          sortable: false,
          width: "5%",
          align: "center",
          value: "sap_code",
        },
        {
          text: "Precio Referencia",
          align: "center",
          value: "final_reference_price",
          sortable: false,
          width: "5%",
        },
        {
          text: "Link de referencia",
          align: "center",
          value: "final_reference_link",
          sortable: false,
          width: "5%",
        },
        {
          text: "Usuario",
          align: "center",
          value: "request_approval_user",
          width: "5%",
          sortable: false,
        },
        {
          text: "Grupo Articulo",
          align: "center",
          value: "material_group",
          width: "5%",
          sortable: false,
        },
        {
          text: "Familia",
          align: "center",
          value: "family",
          width: "5%",
          sortable: false,
        },
        {
          text: "Ceco / Proyecto",
          align: "center",
          value: "is_project",
          width: "5%",
          sortable: false,
        },
        {
          text: "Producto Creado",
          align: "center",
          value: "status",
          width: "10%",
          sortable: false,
        },
        {
          text: "Fecha solicitud",
          align: "center",
          value: "request_approval_date",
          width: "8%",
          sortable: false,
        },
        {
          text: "Fecha creación",
          align: "center",
          value: "sap_date",
          width: "8%",
          sortable: false,
        },
        {
          text: "Tiempo creación",
          align: "center",
          value: "time_creation",
          width: "8%",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      formMaterials: "supplying/formMaterials",
    }),
    wrappedFormMaterials() {
      return this.formMaterials.items;
    },
    wrappedHeaders() {
      return this.tableHeaders;
    },
    ifAnyRequestHaveWarning() {
      return this.wrappedFormMaterials.find((item) => item.warning);
    },
  },
  methods: {
    ...mapActions({
      getFormMaterials: "supplying/getFormMaterials",
      sendDownloadExcelFormMaterials: "supplying/downloadExcelFormMaterials",
      updateStatusFormMaterial: "supplying/updateStatusFormMaterial",
      verifyUserBelongsToSupplying: "supplying/verifyUserBelongsToSupplying",
      clearFormMaterials: "supplying/clearFormMaterials",
      sendUpdateStatusReviewFormMaterial: "supplying/updateStatusReviewForm",
      sendRejectFormMaterial: "supplying/rejectForm",
    }),
    isLinkMaterialValid(link) {
      return isUrlValid(link);
    },
    getTimeCreationDate(item) {
      if (item.sap_date && (item.request_date || item.request_approval_date)) {
        let start_date = item.request_date ?? item.request_approval_date;
        return calculateDiferenceTwoDates(item.sap_date, start_date);
      }
      return "-";
    },
    async getVerifyUserBelongsToSupplying(email) {
      const response = await this.verifyUserBelongsToSupplying({
        email,
      });
      if (response.is_abastecimiento) {
        this.currentUserBelongsToSupplying = response.is_abastecimiento;
      }
    },
    async updateStatusCheckedFormMaterial(material) {
      this.$swal
        .fire({
          icon: "info",
          title: `¿Estas seguro de marcar este material: <b>${material.final_name}</b> como creado`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "#1565C0",
          confirmButtonText: "Aceptar",
          cancelButtonText: `Cancelar`,
          input: "text",
          inputPlaceholder: "Ingresa el código de SAP del Material",
          preConfirm: (inputValue) => {
            if (!inputValue) {
              return this.$swal.showValidationMessage(
                "Por favor, ingresa el código de SAP"
              );
            }
            return inputValue;
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loadingAll = true;
            let payload = {
              id: material.id,
              user: this.user.email,
              sap_code: result.value,
            };
            const resp = await this.updateStatusFormMaterial(payload);
            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 400) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: `Material marcado como creado correctamente`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getFormMaterialsData();
            }
            this.loadingAll = false;
          }
        });
    },
    async updateStatusReviewFormMaterial(material, warning) {
      this.$swal
        .fire({
          icon: "warning",
          title: `¿Estas seguro de ${
            warning ? "enviar esta solicitud" : "marcar esta solicitud"
          }: <b>${material.final_name}</b>  ${
            warning ? "a Revisión" : "Revisada"
          } ?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "warning",
          confirmButtonText: `${warning ? "Enviar" : "Marcar"}`,
          cancelButtonText: `Cancelar`,
          width: "50%",
          input: "textarea",
          inputPlaceholder: "Ingresa el feedback para el usuario solicitante",
          preConfirm: (inputValue) => {
            if (!inputValue) {
              return this.$swal.showValidationMessage(
                "Por favor, ingresa el feedback"
              );
            }
            return inputValue;
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loadingAll = true;
            let payload = {
              request_id: material.id,
              warning: warning ? true : false,
              resolved: !warning ? true : false,
              rejected: false,
              comment: result.value,
              user: this.user.email,
              comment_type: "MATERIAL",
            };
            const resp = await this.sendUpdateStatusReviewFormMaterial(payload);
            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 400) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: `Solicitud enviada a revisión correctamente`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getFormMaterialsData();
            }
            this.loadingAll = false;
          }
        });
    },
    async rejectFormMaterial(material) {
      this.$swal
        .fire({
          icon: "warning",
          title: `¿Estas seguro de Cancelar esta solicitud <b>${material.final_name}</b> ?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "red",
          confirmButtonText: `Cancelar Solicitud`,
          cancelButtonText: `Cerrar`,
          width: "50%",
          input: "textarea",
          inputPlaceholder:
            "Ingresa el motivo de cancelación para el usuario solicitante",
          preConfirm: (inputValue) => {
            if (!inputValue) {
              return this.$swal.showValidationMessage(
                "Por favor, ingresa el motivo de cancelación"
              );
            }
            return inputValue;
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loadingAll = true;
            let payload = {
              request_id: material.id,
              comment: result.value,
              user: this.user.email,
              comment_type: "MATERIAL",
            };
            const resp = await this.sendRejectFormMaterial(payload);
            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 400) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: `Solicitud cancelada correctamente`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getFormMaterialsData();
            }
            this.loadingAll = false;
          }
        });
    },
    openModalCommentsFormMaterials(formMaterial) {
      this.currentFormMaterialComments = formMaterial;
      this.dialogCommentsFormMaterial = true;
    },
    closeModalCommentsFormMaterials() {
      this.currentFormMaterialComments = null;
      this.dialogCommentsFormMaterial = false;
    },
    openModalUpdateOrCreateFormMaterial(formMaterial = null) {
      this.currentFormMaterial = formMaterial;
      this.dialogCreateFormMaterial = true;
    },
    closeModalUpdateOrCreateFormMaterial() {
      this.currentFormMaterial = null;
      this.dialogCreateFormMaterial = false;
    },
    openModalFilesMaterial(formMaterial) {
      this.currentFormMaterialShowFiles = formMaterial;
      this.dialogFilesFormMaterial = true;
    },
    closeModalFilesMaterial() {
      this.currentFormMaterialShowFiles = null;
      this.dialogFilesFormMaterial = false;
    },
    async getFormMaterialsData(page = 1) {
      let self = this;
      clearTimeout(self.debounce);
      self.debounce = setTimeout(async () => {
        self.loading = true;
        await self.getFormMaterials({
          page,
          user: self.user.email,
          filter: self.filters.filter ? self.filters.filter : undefined,
          status_filter: self.filters.only_completed
            ? self.statusMaterial.created
            : undefined,
          in_sap: self.filters.only_pending ? false : undefined,
        });
        self.loading = false;
      }, self.debounceTime);
    },
    async downloadExcelFormMaterials() {
      this.loadingAll = true;
      const response = await this.sendDownloadExcelFormMaterials({
        user: this.user.email,
        filter: this.filters.filter ? this.filters.filter : undefined,
        status_filter: this.filters.only_completed
          ? this.statusMaterial.created
          : undefined,
        in_sap: this.filters.only_pending ? false : undefined,
      });
      let fileURL = window.URL.createObjectURL(new Blob([response]));
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `materiales.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.loadingAll = false;
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(user) {
        if (user) {
          this.getVerifyUserBelongsToSupplying(this.user.email);
        }
      },
    },
    "filters.page": function(page) {
      this.getFormMaterialsData(page);
    },
  },
};
</script>

<style></style>
