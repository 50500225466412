<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="800px"
      scrollable
      @keydown="executeCloseModalCreateMaterial"
      @click:outside="executeCloseModalCreateMaterial"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>
            {{
              currentFormMaterial
                ? "Actualizar Material"
                : "Agregar nuevo Código"
            }}
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="">
            <v-form @submit.prevent="sendAddMaterial">
              <v-row align-content="center">
                <v-col cols="12" md="12" sm="12">
                  <label for="description">
                    Descripción *
                  </label>
                  <v-textarea
                    rows="4"
                    counter
                    outlined
                    placeholder="Descripción del articulo"
                    v-model="$v.form.description.$model"
                    :error="$v.form.description.$invalid && submitUpload"
                    :error-messages="
                      $v.form.description.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0" v-if="showAllForm">
                  <label class="mt-1">
                    Grupo de Articulo *
                  </label>
                  <v-select
                    placeholder="Grupo del articulo"
                    v-model="$v.form.group_article.$model"
                    :error="$v.form.group_article.$invalid && submitUpload"
                    :error-messages="
                      $v.form.group_article.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                    :items="allGroupArticles"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0" v-if="showAllForm">
                  <label class="mt-1">
                    Unidad de Medida *
                  </label>
                  <v-select
                    placeholder="Unidad de medida del articulo"
                    v-model="$v.form.measurement_unit.$model"
                    :error="$v.form.measurement_unit.$invalid && submitUpload"
                    :error-messages="
                      $v.form.measurement_unit.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                    :items="allUnits.filter((unit) => unit)"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0" v-if="showAllForm">
                  <label for="family">
                    Familia *
                  </label>
                  <v-select
                    placeholder="Familia del articulo"
                    v-model="$v.form.family.$model"
                    :error="$v.form.family.$invalid && submitUpload"
                    :error-messages="
                      $v.form.family.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                    :items="allFamilies"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0" v-if="showAllForm">
                  <label for="expense_item">
                    Item de Gasto *
                  </label>
                  <v-select
                    placeholder="Selecciona el item de gasto"
                    v-model="$v.form.expense_item.$model"
                    :error="$v.form.expense_item.$invalid && submitUpload"
                    :error-messages="
                      $v.form.expense_item.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                    :items="allExpenseItems"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="12" sm="12" v-if="showAllForm">
                  <label for="type_destiny">
                    Ceco / Proyecto *
                  </label>
                  <v-select
                    placeholder="Selecciona la opción"
                    v-model="$v.form.type_destiny.$model"
                    :error="$v.form.type_destiny.$invalid && submitUpload"
                    :error-messages="
                      $v.form.type_destiny.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                    :items="allDestinyOptions"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pt-0">
                  <label for="reference_price">
                    Precio de Referencia
                  </label>
                  <v-text-field
                    placeholder="Precio de referencia del articulo"
                    v-model="form.reference_price"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pt-0">
                  <label for="reference_link">
                    Link de Referencia
                  </label>
                  <v-textarea
                    placeholder="Link de referencia del articulo/servicio"
                    rows="3"
                    v-model="form.reference_link"
                  ></v-textarea>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class="pt-0"
                  v-if="showAllForm"
                >
                  <label for="user">
                    Carga de archivos
                  </label>
                  <v-file-input
                    multiple
                    counter
                    show-size
                    chips
                    placeholder="Puede adjuntar imágenes o archivos que faciliten identificar el articulo que requiere"
                    v-model="form.files"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalCreateMaterial"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn
            @click="sendAddMaterial"
            :color="
              `${currentFormMaterial ? 'warning' : 'teal accent-4'} text-white`
            "
            small
            class="float-right"
          >
            {{
              currentFormMaterial ? "Actualizar Material" : "Agregar Material"
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { required } from "vuelidate/lib/validators";
import swal2 from "sweetalert2";

export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalCreateMaterial: {
      type: Function,
      default: () => {},
    },
    showAllForm: {
      type: Boolean,
      default: true,
    },
    currentFormMaterial: {
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        description: null,
        measurement_unit: null,
        group_article: null,
        family: null,
        expense_item: null,
        type_destiny: null,
        reference_price: null,
        reference_link: null,
        files: [],
      },
      submitUpload: false,
    };
  },
  validations: {
    form: {
      description: { required },
      measurement_unit: {
        required,
      },
      group_article: {
        required,
      },
      family: {
        required,
      },
      expense_item: {
        required,
      },
      type_destiny: {
        required,
      },
      reference_price: {},
      reference_link: {},
      files: {},
    },
  },
  mounted() {
    this.getListOptionsSupplying();
  },

  methods: {
    ...mapActions({
      updateOrCreateFormMaterial: "supplying/updateOrCreateFormMaterial",
      getListOptionsSupplying: "supplying/getListOptionsSupplying",
    }),
    async sendAddMaterial() {
      let self = this;
      self.submitUpload = true;
      if (!self.showAllForm || !self.$v.form.$invalid) {
        if (!self.form.description) {
          return swal2.fire({
            icon: "error",
            title: "Error",
            text: "La descripción es requerida",
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 4000,
            timerProgressBar: true,
          });
        }
        self.loading = true;
        const resp = await self.updateOrCreateFormMaterial({
          id: self.currentFormMaterial
            ? self.currentFormMaterial.id
            : undefined,
          name: self.form.description,
          item_group: self.form.group_article
            ? self.form.group_article
            : undefined,
          item_gasto: self.form.expense_item
            ? self.form.expense_item
            : undefined,
          ceco_proyecto: self.form.type_destiny
            ? self.form.type_destiny
            : undefined,
          completed: self.showAllForm,
          user: self.user.email,
          ...self.form,
        });
        if (resp.status == 200) {
          swal2.fire({
            icon: "success",
            title: "Excelente",
            text: self.currentFormMaterial
              ? "Código creado correctamente, sera notificado una vez se haya creado el material"
              : "Material modificado correctamente",
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true,
          });
          this.$emit("materialCreated");
          this.closeModalCreateMaterial(true);
          this.resetForm();
        }
        if (!resp.status || resp.status === 400) {
          self.loading = false;
          return swal2.fire({
            icon: "error",
            title: "Error",
            text: resp.data.detail,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 10000,
            timerProgressBar: true,
          });
        }
        this.loading = false;
      }
    },
    executeCloseModalCreateMaterial($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.closeModalCreateMaterial($event);
      }
    },
    setFormMaterial(formMaterial) {
      this.form = {
        description: formMaterial.final_name,
        measurement_unit: formMaterial.measurement_unit,
        group_article: formMaterial.material_group,
        family: formMaterial.family,
        expense_item: formMaterial.item_gasto,
        type_destiny: formMaterial.is_project
          ? this.allDestinyOptions[0]
          : this.allDestinyOptions[1],
        reference_price: formMaterial.final_reference_price,
        reference_link: formMaterial.final_reference_link,
      };
    },
    resetForm() {
      this.form = {
        description: null,
        measurement_unit: null,
        group_article: null,
        family: null,
        expense_item: null,
        type_destiny: null,
        reference_price: null,
        reference_link: null,
        files: [],
      };
      this.submitUpload = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      allGroupArticles: "supplying/allGroupArticles",
      allFamilies: "supplying/allFamilies",
      allExpenseItems: "supplying/allExpenseItems",
      allUnits: "supplying/allUnits",
      allDestinyOptions: "supplying/allDestinyOptions",
    }),
  },
  watch: {
    currentFormMaterial(newForm) {
      if (newForm) {
        this.setFormMaterial(newForm);
      } else {
        this.resetForm();
      }
    },
  },
};
</script>

<style></style>
